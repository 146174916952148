.tag-list {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
	color: $dark-02;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	li {
		padding: 3px 11px;
		background: $lightbrown;
		border-radius: 5px;
		margin: 0 15px 15px 0;
	}
}
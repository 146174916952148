// vars
$images: '../images/';
$fonts: '../fonts/';
$fa-font-path: '../fonts/fontawesome';

// fonts
$iconfont: 'FontAwesome';
$futura: 'Futura', sans-serif;
$openSans: 'Open Sans', sans-serif;

// bootstrap grid
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-gutter-width-mobile: $grid-gutter-width;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1166px
);

// colors
$white: #fff;
$black: #000;
$dark: #222222;
$dark-02: #4d4c4c;
$olive-01: #62683a;
$olive-02: #64561a;
$olive-03: #7d8454;
$olive-04: #695b1f;
$gray-01: #e5e5e5;
$gray-02: #f0e9cc;
$gray-03: #fffdf5;
$brown: #a64b00;
$lightbrown: #beb48a;
$lightbrown-02: #aea067;

// base vars
$text-color-base: $dark;
$text-line-height-base: 1.4;
$body-bg: $gray-02;
$body-color: $dark;
$font-family-base: $openSans;
$font-size-base: 1.8rem;
$line-height-base: 1.388888;
$links-color-base: inherit;
$headings-font-weight: 800;

$transition: all .3s ease;

// bootstrap headings
$h1-font-size: 4.8rem;
$h2-font-size: 3.6rem;
$h3-font-size: 2.4rem;
$h4-font-size: 1.8rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.4rem;
$headings-color: $dark;

// bootstrap buttons
$btn-border-radius: 1rem;
$btn-font-weight: 800;
$btn-font-size: 2.5rem;
$btn-line-height: 1;
$btn-padding-y: 1.7rem;
$btn-padding-x: 4.2rem;
$btn-font-family: $openSans;
$btn-font-size-sm: 2.2rem;
$btn-line-height-sm: $btn-line-height;
$btn-border-radius-sm: $btn-border-radius;
$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: 3rem;

// bootstrap forms
$input-bg: rgba($white, 1);
$input-color: $black;
$input-placeholder-color: rgba($black, 0.5);
$input-border-color: transparent;
$input-font-weight: 500;
$input-font-size: 1.6rem;
$input-line-height: 1.2;
$input-padding-x: 2rem;
$input-padding-y: 1.5rem;
$input-height: 5rem;
$input-border-radius: 0;
$input-box-shadow: none;
$input-focus-box-shadow: $input-box-shadow;
$input-focus-border-color: $black;
$form-group-margin-bottom: 1rem;

// dev2
// #b7ad82 = darken(desaturate($lightbrown, 1.67), 2.94)
// dev3
// dev4
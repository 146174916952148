@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/solid";
@import "fontawesome/regular";


@font-face {
	font-family: 'Futura';
	src: url('#{$fonts}FuturaMediumC.woff2') format('woff2'),
	url('#{$fonts}FuturaMediumC.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fonts}subset-OpenSans.woff2') format('woff2'),
	url('#{$fonts}subset-OpenSans.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fonts}subset-OpenSans-Semibold.woff2') format('woff2'),
	url('#{$fonts}subset-OpenSans-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fonts}subset-OpenSans-Extrabold.woff2') format('woff2'),
	url('#{$fonts}subset-OpenSans-Extrabold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fonts}subset-OpenSans-Light.woff2') format('woff2'),
	url('#{$fonts}subset-OpenSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fonts}subset-OpenSans-Bold.woff2') format('woff2'),
		url('#{$fonts}subset-OpenSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}






// dev2
// dev3
// dev4
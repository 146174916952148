/* buttons */
.btn {

	@include media-breakpoint-down(md) {
		font-size: 25px;
		padding: 17px 42px;
	}

	&:hover {
		text-decoration: none;
	}

	.fa {
		margin-left: 5px;
	}

	&-primary {
		@include button-variant($olive-01, $olive-01);
		text-transform: uppercase;
	}

	&-outline-olive {
		@include button-outline-variant($olive-02);
		text-transform: uppercase;
	}

	&-light {
		@include button-variant($gray-02, $gray-02, $olive-02, $olive-02, $olive-02);
		text-transform: uppercase;
	}

	&-sm {
		font-weight: 800;
		font-size: 16px;
		border-radius: 6px;
		padding: 14px 20.5px;
	}
}
.intro {
	padding: 150px 0 95px;
	height: 100vh;
	height: calc((var(--vh, 1vh) * 100));
	display: flex;
	align-items: flex-end;
	margin-bottom: 50px;

	@include media-breakpoint-down(lg) {
		padding-bottom: 100px;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		height: auto;
		padding-top: 0;
		padding-bottom: 20px;
		margin-bottom: 30px;
	}

	.swiper-intro {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		@include media-breakpoint-down(md) {
			position: relative;
			height: 300px;
		}

		.swiper-slide {
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.swiper-pagination {
			bottom: 55px;
			@include media-breakpoint-down(md) {
				bottom: 19px;
				padding-left: 6px;
			}
		}
	}

	.container {
		position: relative;
		z-index: 10;

		@include media-breakpoint-down(md) {
			padding: 0 20px;
		}
	}

	.text-holder {
		color: $gray-02;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0.016em;
		padding-right: 15px;

		@include media-breakpoint-down(md) {
			padding-right: 0;
			padding-top: 15px;
			color: $dark;
			font-size: 18px;
			margin-bottom: 50px;
		}

		h1 {
			margin-bottom: 15px;
			line-height: 1.35;
			letter-spacing: 0;

			@include media-breakpoint-down(md) {
				line-height: 1.3;
			}
		}

		p {
			margin: 0;
		}
	}

	.btn-wr {
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: 30px;

		@include media-breakpoint-down(md) {
			justify-content: center;
			margin-top: 0;
		}

		.btn {
			font-family: $futura;
		}
	}
}
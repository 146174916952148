.house-summary {
	margin-bottom: 38px;

	@include media-breakpoint-down(md) {
		margin-bottom: 85px;
	}

	.left-col {
		width: 47%;
		margin-bottom: 73px;

		@include media-breakpoint-down(md) {
			width: 100%;
			margin-bottom: 45px;
		}
	}

	.right-col {
		width: 53%;
		margin-bottom: 73px;

		@include media-breakpoint-down(md) {
			width: 100%;
			margin-bottom: 39px;
		}
	}

	.text-holder {
		padding-top: 20px;
		font-size: 24px;
		letter-spacing: .013em;
		line-height: 1.3;

		@include media-breakpoint-down(md) {
			padding-top: 0;
		}

		h2 {
			margin-bottom: 6px;

			@include media-breakpoint-down(md) {
				margin-bottom: 10px;
			}
		}

		p {
			margin-bottom: 28px;

			@include media-breakpoint-down(md) {
				margin-bottom: 16px;
				max-width: 90%;
			}
		}
	}

	.params-list {
		margin-bottom: 26px;

		@include media-breakpoint-down(md) {
			padding-left: 5px;
			margin-bottom: 23px;
		}
	}

	.btn-wr {
		text-align: right;
		margin-bottom: 73px;

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-bottom: 45px;
		}

		.btn {
			font-weight: 700;
			padding: 17px 37px;
		}
	}

	.description {
		padding: 0 40px 0 5px;
		line-height: 1.4;
		position: relative;
		height: 490px;
		overflow-y: hidden;
		transition: $transition;

		@include media-breakpoint-down(md) {
			padding: 0 12px 0 9px;
			height: 1372px;
		}

		&::after {
			content: '';
			height: 103px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba($gray-02, .1) 0%, rgba($gray-02, 1) 85%);
			opacity: 1;
			visibility: visible;
		}

		&.show-text {
			height: auto;

			&::after {
				opacity: 0;
				visibility: hidden;
			}
		}

		p {
			margin-bottom: 25px;
		}
	}

	.open-text-link {
		padding-left: 3px;

		@include media-breakpoint-down(md) {
			padding-left: 7px;
		}
	}
}
.reviews-module {
	background: $lightbrown;
	padding: 85px 0 70px;


	@include media-breakpoint-down(md) {
		padding-bottom: 7px;
		padding-top: 42px;
		background: $lightbrown-02;
	}

	.section-header {
		margin-bottom: 43px;

		@include media-breakpoint-down(md) {
			margin-bottom: 50px;
		}
	}

	.review-item {
		display: flex;
		align-items: flex-start;
		padding-right: 77px;
		margin-bottom: 37px;

		@include media-breakpoint-down(xl) {
			padding-right: 0;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: center;
			margin-bottom: 50px;
		}

		&.reverse {
			flex-direction: row-reverse;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

			.img-wr {
				margin-right: 0;
				margin-left: 20px;

				@include media-breakpoint-down(md) {
					margin: 0 0 22px;
				}
			}

			.text-holder {
				padding-left: 10px;

				@include media-breakpoint-down(md) {
					text-align: center;
				}
			}
		}

		.img-wr {
			margin-right: 30px;
			height: 100px;
			width: 100px;
			flex-shrink: 0;

			@include media-breakpoint-down(md) {
				margin: 0 0 22px;
				width: 113px;
				height: 113px;
			}

			img {
				max-width: 100%;
				border-radius: 100%;
				width: 100%;
			}
		}

		.text-holder {
			@include media-breakpoint-down(md) {
				text-align: center;
			}

			p {
				margin-bottom: 16px;

				@include media-breakpoint-down(md) {
					margin-bottom: 13px;
				}

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.author {
				font-weight: 800;
			}
		}
	}

	.btn-wr {
		padding-top: 15px;
		text-align: center;
	}
}
.amenities-module {
	margin-bottom: 30px;

	@include media-breakpoint-down(md) {
		margin-bottom: 35px;
	}

	&.columns-4 {
		.item {
			align-items: center;
			margin-bottom: 4px;
		}
	}

	&.last {
		margin-bottom: 75px;

		@include media-breakpoint-down(md) {
			margin-bottom: 112px;
		}
	}

	.section-header {
		margin-bottom: 55px;

		h3 {
			font-size: 21px;
			text-align: left;
			padding-bottom: 18px;
			position: relative;

			&:after {
				content: '';
				width: 60px;
				height: 2px;
				background: $olive-01;
				position: absolute;
				bottom: 0;
				left: 0;

				@include media-breakpoint-down(md) {
					width: 57px;
				}
			}
		}
	}

	.item {
		display: flex;
		align-items: flex-start;
		margin-bottom: 42px;

		.ico-wr {
			margin-right: 20px;

			img {
				min-width: 37px;
			}
		}

		.text-holder {
			h3 {
				font-size: 21px;
				font-weight: 400;
				margin-bottom: 10px;
			}

			p {
				margin: 0;
			}
		}
	}
}
/* footer */
#footer {
	padding: 70px 0;

	@include media-breakpoint-down(md) {
		padding: 66px 0 0;
	}

	.footer-inner {
		padding: 72px 0;
		display: flex;
		align-items: flex-start;
		border-top: 1px solid $olive-01;

		@include media-breakpoint-down(lg) {
			justify-content: space-between;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			padding: 50px 0;
		}
	}

	.container {
		padding-right: 65px;

		@include media-breakpoint-down(xl) {
			padding-right: 20px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.left {
		margin-right: 160px;

		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}

		ul {
			padding-left: 18px;

			@include media-breakpoint-down(md) {
				padding-left: 5px;
			}
		}
	}

	.center {
		ul {
			padding-top: 24px;

			@include media-breakpoint-down(md) {
				padding-left: 8px;
				margin-bottom: 4px;
			}

			li {
				&:not(:last-child) {
					margin-bottom: 5px;
				}

				&.active {
					font-weight: 800;

					a {
						pointer-events: none;
					}
				}
			}
		}
	}

	.right {
		margin-left: auto;
		padding-top: 15px;
		padding-right: 5px;

		@include media-breakpoint-down(lg) {
			margin-left: 0;
		}

		@include media-breakpoint-down(md) {
			padding-right: 0;
			padding-left: 5px;
		}

		h4 {
			margin-bottom: 20px;

			@include media-breakpoint-down(md) {
				font-size: 18px;
			}
		}

		ul {
			display: flex;
			align-items: flex-end;

			@include media-breakpoint-down(md) {
				padding-left: 3px;
			}

			li {
				&:not(:last-child) {
					margin-right: 25px;
				}
			}
		}
	}

	.logo {
		margin-left: 12px;
		margin-bottom: 28px;
		display: flex;

		@include media-breakpoint-down(md) {
			margin-left: 0;
		}

		img {
			width: 235px;

			@include media-breakpoint-down(lg) {
				padding-left: 5px;
				width: 214px;
			}

			@include media-breakpoint-down(md) {
				width: 239px;
			}
		}
	}
}
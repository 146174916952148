/* header */
#header {
	padding: 42px 0 26px;
	z-index: 100;
	transition: $transition;

	@include media-breakpoint-down(md) {
		padding: 37px 0;
	}

	&.home {
		padding: 34px 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		@include media-breakpoint-down(md) {
			padding: 37px 0;
		}

		.main-nav {
			color: $gray-02;
		}

		.open-menu {
			color: $gray-02;

			&:hover {
				color: $gray-02;
			}

			.menu-opened & {
				color: $gray-02;
			}
		}

		// .navbar-holder {
		// 	@include media-breakpoint-down(md) {
		// 		height: calc(100vh - 124px);
		// 		height: calc((var(--vh, 1vh) * 100) - 124px);
		// 	}
		// }
	}

	.menu-opened & {
		background: $olive-03;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		width: 249px;

		@include media-breakpoint-down(lg) {
			padding-left: 5px;
			width: 214px;
		}
		@include media-breakpoint-down(md) {
			padding-left: 0;
		}
	}

	.navbar-holder {
		@include media-breakpoint-down(md) {
			position: fixed;
			left: 0;
			bottom: 0;
			background: $olive-03;
			opacity: 0;
			visibility: hidden;
			transition: $transition;
			width: 100%;
			height: calc(100vh - 124px);
			height: calc((var(--vh, 1vh) * 100) - 124px);
			padding: 15px 0 30px;
			overflow-y: auto;

			.menu-opened & {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.main-nav {
		margin: 0 0 2px;
		padding: 0 11px 0 0;
		list-style: none;
		display: flex;
		align-items: center;
		color: $dark;
		font-family: $futura;
		font-weight: 500;
		letter-spacing: .073em;

		@include media-breakpoint-down(lg) {
			letter-spacing: 0;
		}

		@include media-breakpoint-down(md) {
			font-family: $openSans;
			font-weight: 600;
			font-size: 40px;
			flex-direction: column;
			padding: 0;
			margin: 0;
			line-height: 1.15;
		}

		li {

			@include media-breakpoint-down(md) {
				text-align: center;
			}

			&:not(:last-child) {
				margin-right: 36px;

				@include media-breakpoint-down(lg) {
					margin-right: 25px;
				}

				@include media-breakpoint-down(md) {
					margin: 0 0 31px;
				}
			}

			&.active {
				a {
					color: $olive-01;
					pointer-events: none;
				}
			}

			a {
				&:hover {
					text-decoration: none;
					color: $olive-01;
				}
			}
		}
	}

	.open-menu {
		display: none;
		width: 40px;
		height: 30px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		position: relative;
		color: $dark;
		margin: 0 -7px 3px 0;

		@include media-breakpoint-down(md) {
			display: flex;
		}

		.menu-opened & {
			color: $dark;
			margin-bottom: 0;
		}

		span {
			width: 100%;
			border-bottom: 3px solid;
			position: absolute;
			top: 50%;
			right: 0;
			width: 100%;
			transform: translateY(-50%);
			transition: all 0.3s;

			&:nth-child(1) {
				margin-top: -7px;
				width: 78%;
			}

			&:nth-child(2) {
				margin-top: 0;
				width: 60%;
			}

			&:nth-child(3) {
				margin-top: 7px;
				width: 78%;
			}

			.menu-opened & {
				&:nth-child(1) {
					width: 100%;
					margin-top: 0;
					transform: translateY(-50%) rotate(45deg);
				}

				&:nth-child(2) {
					opacity: 0;
					width: 0;
				}

				&:nth-child(3) {
					width: 100%;
					margin-top: 0;
					transform: translateY(-50%) rotate(-45deg);
				}
			}
		}

		&:hover {
			color: $dark;
		}
	}
}
.houses-module {
	padding: 26px 0 0;
	margin-bottom: 97px;
	overflow-x: hidden;

	@include media-breakpoint-down(md) {
		padding-top: 15px;
		margin-bottom: 85px;
	}

	.section-header {
		margin-bottom: 90px;

		@include media-breakpoint-down(lg) {
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 72px;
			text-align: left;
		}

		h2 {
			&::after {
				@include media-breakpoint-down(md) {
					left: 4px;
					transform: none;
					width: 82px;
				}
			}
		}
	}

	.container {
		padding: 0 25px 0 5px;

		@include media-breakpoint-down(lg) {
			padding: 0 20px;
		}
	}

	.house-item {
		padding-top: 90px;
		position: relative;

		@include media-breakpoint-down(lg) {
			padding-top: 0;
		}

		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
		}

		&:not(:last-child) {
			margin-bottom: 85px;

			@include media-breakpoint-down(md) {
				margin-bottom: 70px;
			}
		}

		&.reverse {
			.swiper-house {
				left: auto;
				right: 0;
			}

			.header {
				margin-left: 0;
				padding-left: 40px;

				@include media-breakpoint-down(xl) {
					padding-left: 30px;
				}

				@include media-breakpoint-down(md) {
					padding-left: 20px;
				}
			}

			.content-wr {
				margin-left: 0;
				margin-right: auto;

				@include media-breakpoint-down(md) {
					width: auto;
					margin: 0;
					margin: -12px -20px 0 -20px;
					padding-top: 28px;
				}
			}

			.text-holder {
				margin-left: 0;
				margin-right: auto;
				padding-left: 40px;

				@include media-breakpoint-down(xl) {
					padding-left: 30px;
				}

				@include media-breakpoint-down(md) {
					padding: 0 20px;
					width: 100%;
				}
			}

			footer {
				padding-left: 43px;
				padding-right: 20px;
				flex-direction: row-reverse;

				@include media-breakpoint-down(xl) {
					padding-left: 27px;
					padding-right: 27px;
				}

				@include media-breakpoint-down(lg) {
					flex-direction: column;
				}

				ul {
					@include media-breakpoint-down(lg) {
						flex-wrap: wrap;
					}
				}

				.btn {
					margin-left: 0;
					margin-right: 40px;

					@include media-breakpoint-down(xl) {
						margin-right: 20px;
					}

					@include media-breakpoint-down(md) {
						margin: 0;
					}
				}
			}
		}
	}

	.swiper-house {
		position: absolute;
		top: 0;
		left: 0;
		width: 48.5%;

		@include media-breakpoint-down(lg) {
			top: 20px;
		}

		@include media-breakpoint-down(md) {
			position: relative;
			width: auto;
			top: 0;
			margin: 0 -20px 0 -3px;
		}

		.swiper-slide {
			height: auto;
			flex-grow: 1;

			img {
				max-width: 100%;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.swiper-pagination {
			bottom: 21px;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.content-wr {
		background: $gray-03;
		padding: 58px 0 0;
		margin-left: auto;
		width: calc(100% - 65px);
		min-height: 450px;
		display: flex;

		@include media-breakpoint-down(xl) {
			min-height: 360px;
		}

		@include media-breakpoint-down(lg) {
			min-height: 470px;
			padding-top: 20px;
		}

		@include media-breakpoint-down(md) {
			width: auto;
			margin: 0;
			margin: -12px -20px 0 -20px;
			padding-top: 28px;
		}

		.row {
			flex-grow: 1;
		}
	}

	.header {
		display: flex;
		align-items: flex-end;
		font-size: 24px;
		font-weight: 300;
		letter-spacing: -.04em;
		line-height: 1.5;
		margin-bottom: 70px;
		margin-top: -113px;

		@include media-breakpoint-down(lg) {
			margin-top: 0;
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-left: 20px;
			margin-bottom: 25px;
		}

		h3 {
			font-size: 36px;
			font-weight: 800;
			letter-spacing: -.045em;
			margin: 0 30px 0 0;

			@include media-breakpoint-down(md) {
				margin-right: 9px;
			}

		}

		p {
			margin: 0;
		}
	}

	.text-holder {
		line-height: 1.4;
		padding: 0 75px 0 0;

		@include media-breakpoint-down(xl) {
			padding-right: 30px;
		}

		@include media-breakpoint-down(md) {
			padding: 0 20px;
			width: 100%;
		}

		p {
			margin-bottom: 17px;

			@include media-breakpoint-down(md) {
				margin-bottom: 15px;
			}
		}
	}

	.footer-wr {
		display: flex;
		align-items: flex-end;
	}

	footer {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 24px 27px 20px;
		margin-top: auto;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}

		@include media-breakpoint-down(md) {
			padding: 0 20px 38px;
		}



		.btn {
			font-size: 16px;
			font-weight: 800;
			padding: 14px 39px;
			margin-left: 58px;

			@include media-breakpoint-down(xl) {
				margin-left: 20px;
			}

			@include media-breakpoint-down(md) {
				margin: 0;
				padding: 14px 34.5px;
			}
		}
	}
}
/* calendar-section */
.calendar-section {
	overflow: hidden;
	position: relative;
	z-index: 0;
	background: $lightbrown;
	padding-top: 4.6rem;
	padding-bottom: 3.8rem;
	margin-bottom: 5.5rem;

	@include media-breakpoint-up(lg) {
		padding-top: 6.6rem;
		padding-bottom: 2rem;
		margin-bottom: 6rem;
	}

	.decor-str {
		position: absolute;
		top: -0.275em;
		left: 50%;
		z-index: -1;
		margin-left: 1.05em;
		font-size: 10rem;
		line-height: 1;
		color: darken(desaturate($lightbrown, 1.67), 2.94);
		pointer-events: none;

		@include media-breakpoint-down(md) {
			display: none;
		}

		@include media-breakpoint-up(xl) {
			font-size: 17rem;
		}
	}

	.section-header {
		margin-bottom: 3.5rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 2.2rem;
		}

		h2,
		.h2 {
			@include media-breakpoint-down(md) {
				font-size: 2.4rem;
				padding-bottom: 2.3rem;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: 2.1rem;
			}
		}
	}

	.poweredby {
		display: none !important;
	}
}
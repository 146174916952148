.gallery-module {
	margin-bottom: 37px;

	.container {
		position: relative;

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}

	.swiper-gallery {
		@include media-breakpoint-down(md) {
			height: 290px;
		}

		.swiper-slide {
		
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;

			}
		}

		.swiper-pagination {
			font-size: 12px;
			color: $white;
			background: $olive-04;
			border-radius: 4px;
			width: 40px;
			position: absolute;
			bottom: 8px;
			right: 11px;
			left: auto;
			padding: 2px 0;
		}
	}

	.col-left {
		width: 48.5%;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.col-right {
		width: 51.5%;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.img-group {
		display: flex;
		flex-wrap: wrap;

		.img-wr {
			width: 50%;
		}
	}

	.img-wr {
		margin: 0;

		img {
			max-width: 100%;
			height: 100%;
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}
	}

	.btn {
		position: absolute;
		bottom: 39px;
		right: 52px;

		@include media-breakpoint-down(lg) {
			bottom: 20px;
			right: 35px;
		}
	}
}
.params-list {
	margin: 0;
	padding: 5px 0 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}

	@include media-breakpoint-down(md) {
		padding-top: 12px;
		margin-bottom: 35px;
	}

	&.column {
		flex-direction: column;
		align-items: flex-start;

		li {
			margin-bottom: 21px;
		}
	}

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 20px;
		line-height: 1.3;

		@include media-breakpoint-down(xl) {
			font-size: 16px;
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			justify-content: flex-start;
			align-items: flex-end;
			font-size: 20px;
			margin-bottom: 12px;
		}

		img {
			margin-right: 6px;

			@include media-breakpoint-down(xl) {
				width: 25px;
			}

			@include media-breakpoint-down(md) {
				width: auto;
				margin-top: 0;
			}
		}

		.ttl {
			font-weight: 800;
			display: inline-block;
			margin: 0 12px;
		}

		.value {
			font-weight: 400;
		}
	}
}
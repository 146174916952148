html {
	font-size: 10px;

	// media tablet
	@include media-breakpoint-down(sm) {
		// font-size: 8px;
	}
}

body {
	margin: 0;
	min-width: 320px;
	background: $body-bg;
	color: $body-color;
	font: #{$font-size-base}/$line-height-base $font-family-base;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
	color: $links-color-base;
	transition: $transition;
}

a:hover {
	text-decoration: underline;
	color: $olive-01;
}

button,
input[type="search"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: $transition;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	border: none;
	padding: 0;
}

.wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

#main {
	flex-grow: 1;
}

@for $i from 1 through 6 {
	h#{$i} {
		margin: 0 0 0.5em;
		font-weight: $headings-font-weight;

		a {

			&,
			&:hover {
				color: inherit;
			}
		}
	}
}

h1 {
	color: inherit;
}

h2 {
	@include media-breakpoint-down(md) {
		font-size: 3.6rem;
	}
}

h3 {}

.swiper-pagination {
	z-index: 20;
	bottom: 54px;
	padding-left: 2px;

	&-bullet {
		width: 57px;
		height: 1px;
		border-top: 2px solid $gray-02;
		border-radius: 0;
		opacity: 1;
		margin: 5px 27.5px 5px 0;

		@include media-breakpoint-down(lg) {
			width: 38px;
			margin-right: 16px;
		}

		&:hover {
			cursor: pointer;
		}

		&:focus {
			outline: 0;
		}
	}

	&-bullet-active {
		border-color: $brown;
	}
}

.swiper-container-horizontal {
	>.swiper-pagination-bullets {
		.swiper-pagination-bullet {
			margin: 5px 27.5px 5px 0;
		}
	}
}

.container {
	@include media-breakpoint-down(md) {
		padding: 0 20px;
	}
}


// dev2
// dev3
// dev4
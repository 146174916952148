.section-header {
	font-size: 24px;
	line-height: 1.3;
	text-align: center;
	letter-spacing: .015em;
	margin-bottom: 40px;

	@include media-breakpoint-down(md) {
		// text-align: left;
		font-size: 18px;
	}

	h2 {
		position: relative;
		padding-bottom: 27px;
		margin-bottom: 22px;
		letter-spacing: 0;

		@include media-breakpoint-down(md) {
			font-size: 36px;
			padding-bottom: 18px;
			margin-bottom: 20px;
		}

		&::after {
			content: '';
			width: 80px;
			height: 2px;
			background: $olive-01;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include media-breakpoint-down(md) {
				width: 57px;
			}
		}
	}

	p {
		margin: 0;
	}
}
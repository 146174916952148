.map-wr {
	@include media-breakpoint-down(md) {
		margin-left: -20px;
		margin-right: -20px;
	}
	.map {
		width: 100%;
		height: 290px;

		@include media-breakpoint-down(md) {
			height: 182px;
		}
	}
}
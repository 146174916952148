.bg-img {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

// dev2
// dev3
// dev4
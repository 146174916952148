.open-text-link {
	font-size: 18px;
	font-weight: 800;
	color: $olive-01;
	display: flex;
	align-items: center;
	line-height: 1;

	&::before {
		content: '+';
		font-weight: 300;
		font-size: 31px;
		margin-right: 4px;
	}

	&:hover {
		text-decoration: none;
		color: $dark;
	}

	&.active {
		&::before {
			content: '-';
		}
	}

	span {
		margin-left: 5px;
	}
}